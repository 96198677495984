export const EventEmitter = {
    events: {} as Record<string, Array<(data?: any) => void>>,
    subscribe(event: string, callback: (data?: any) => void) {
        if (!this.events[event]) this.events[event] = [];
        this.events[event].push(callback);
    },
    dispatch(event: string, data?: any) {
        if (!this.events[event]) return;
        this.events[event].forEach((callback) => callback(data));
    },
    unsubscribe(event: string) {
        if (!this.events[event]) return;
        delete this.events[event];
    },
};
