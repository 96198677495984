export { default as IconCaret } from './caret.svg';
export { default as IconClose } from './close.svg';
export { default as IconDownload } from './download.svg';
export { default as IconEmail } from './email.svg';
export { default as IconFilter } from './filter.svg';
export { default as IconLink } from './link.svg';
export { default as IconLoading } from './loading.svg';
export { default as IconMenu } from './menu.svg';
export { default as IconPaste } from './paste.svg';
export { default as IconPhone } from './phone.svg';
export { default as IconQuoteClosing } from './quote_closing.svg';
export { default as IconQuoteOpening } from './quote_opening.svg';
export { default as IconGlobe } from './globe.svg';
export { default as IconSearch } from './search.svg';
export { default as IconSearchCaret } from './search-caret.svg';
export { default as LogoPrezly } from './prezly.svg';
