import type { HeadingNode } from '@prezly/story-content-format';
import { Alignment } from '@prezly/story-content-format';
import classNames from 'classnames';
import type { ReactNode } from 'react';

import styles from './Title.module.scss';

interface Props {
    children?: ReactNode;
    node: HeadingNode;
}

export function Title({ children, node }: Props) {
    return (
        <h1
            className={classNames(styles.title, {
                [styles.alignLeft]: node.align === Alignment.LEFT,
                [styles.alignCenter]: node.align === Alignment.CENTER,
                [styles.alignRight]: node.align === Alignment.RIGHT,
            })}
        >
            {children}
        </h1>
    );
}
