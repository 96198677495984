import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import type { FunctionComponent, PropsWithChildren, ReactNode, SVGProps } from 'react';
import { useEffect, useState } from 'react';

import { useBlur } from '@/contexts/blur-context';
import { useDevice } from '@/hooks/useDevice';
import { IconCaret, IconClose } from '@/icons';
import { Button } from '@/ui';
import { makeComposableComponent } from '@/utils';
import { EventEmitter } from '@/utils/events';

import Item from './DropdownItem';

import styles from './Dropdown.module.scss';

type Props = {
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    label?: ReactNode;
    className?: string;
    menuClassName?: string;
    menuWrapperClassName?: string;
    buttonClassName?: string;
    withMobileDisplay?: boolean;
    showCaret: boolean;
    specialId?: string;
};

function Dropdown({
    icon,
    label,
    className,
    menuClassName,
    menuWrapperClassName,
    buttonClassName,
    withMobileDisplay,
    children,
    showCaret,
    specialId,
}: PropsWithChildren<Props>) {
    const { isTablet } = useDevice();
    const [menuOpen, setMenuOpen] = useState(false);
    const { toggleBlur } = useBlur();

    function closeMenu() {
        setMenuOpen(false);

        toggleBlur(false);
    }

    function openMenu() {
        EventEmitter.dispatch('closeAllDropdowns');

        setMenuOpen(true);

        toggleBlur(true);
    }

    useEffect(() => {
        function onCloseAllDropdowns() {
            setMenuOpen(false);

            toggleBlur(false);
        }

        EventEmitter.subscribe('closeAllDropdowns', onCloseAllDropdowns);

        return () => {
            EventEmitter.unsubscribe('closeAllDropdowns');
        };
    }, [toggleBlur]);

    return (
        <Menu id={specialId} as="div" className={classNames(styles.container, className)}>
            {() => {
                function getLeftShiftPosition() {
                    let containerWidth = 0;
                    if (menuOpen && specialId) {
                        const containerElement = document.getElementById(specialId);
                        if (containerElement) {
                            containerWidth = containerElement.getBoundingClientRect().width;
                        }
                    }

                    return containerWidth - 328;
                }
                return (
                    <>
                        <Menu.Button as="div">
                            <Button
                                variation="navigation"
                                icon={icon}
                                className={classNames(buttonClassName, {
                                    [styles.buttonWithMobileDisplay]: withMobileDisplay,
                                })}
                                onClick={openMenu}
                            >
                                {label}
                                {showCaret && (
                                    <IconCaret
                                        className={classNames(styles.caret, {
                                            [styles.caretOpen]: menuOpen,
                                        })}
                                    />
                                )}
                            </Button>
                        </Menu.Button>

                        {menuOpen && (
                            <div
                                className={classNames(menuWrapperClassName, {
                                    [styles.active]: menuOpen,
                                })}
                            >
                                <Menu.Items
                                    style={{ left: getLeftShiftPosition(), overflow: 'inherit' }}
                                    as="ul"
                                    className={classNames(styles.menu, menuClassName, {
                                        [styles.withMobileDisplay]: withMobileDisplay,
                                    })}
                                    static
                                >
                                    {!isTablet && (
                                        <div className={styles.closeWrapper}>
                                            <Button
                                                variation="navigation"
                                                icon={IconClose}
                                                onClick={closeMenu}
                                            />
                                        </div>
                                    )}

                                    {children}
                                </Menu.Items>
                            </div>
                        )}
                    </>
                );
            }}
        </Menu>
    );
}

export default makeComposableComponent(Dropdown, { Item });
